<template>
  <div class="git-iot">

    <div class="git-iot-banner">
      <img src="../../assets/img/gitiot-banner.png" class="img">
      <div>
        <p>GitIoT—国产智联网开发应用中枢 <a-icon v-if="0" class="play-icon" type="play-circle" theme="filled" @click="visible=true" /></p>
        <p>GitIoT是中软鸿联联创营面向物联网开发服务的全新商业品牌，基于开发应用中枢GitIoT，满足产品智能化需求，快速搭建全场景解决方案，并提供端到端交付服务。</p>
        <a-button class="btn-red git-btn udesk">立即咨询</a-button>
      </div>
    </div>

    <v-pop-video :visible.sync="visible" :videoURL="videoURL"></v-pop-video>


    <div class="git-service">

            <div>GitIoT产品服务矩阵图</div>
            <p>中软国际作为产品智能化创新者、国产智联网建设专家，KaihongOS为底座，
              打造的云-管-边-端一体化服务体系，并通过国产智联网开发应用中枢GitIoT,
              提供从全场景解决方案到端到端交付的一站式服务，推动产品智能化升级</p>
            <img src="@/assets/img/matrix.png" alt="">


    </div>

    <div class="git-item1">
        <div class="git-item-content">
          <img src="@/assets/img/git1.png" alt="">
          <div class="content-text">
            <div>GitIoT鸿易云--原生基础技术支撑</div>
            <p>基于原生基础技术能力为物联网行业的应用部署提供一个全托管、免运维、高弹性的数字化底座。
              对下屏蔽了基础架构使用和维护的复杂性，
              对上提供应用全生命周期管理、微服务治理、APM、弹性管理等能力，
              让企业更简单地通过AIoT应用的容器化完成应用迁移和业务上云。</p>

            <div class="item-list">
              <div class="item-box" v-for="(el,index) in items1" :key="index" @mouseenter="activeChange('itemActive1',index)">
                <div>
                  <span>{{el.title}}</span>
                  <a-icon v-if="active.itemActive1===index" type="down" style="color: #9A9A9A" />
                  <a-icon v-else type="up" style="color: #9A9A9A" />

                </div>
                <div :class="{'item-con-text':active.itemActive1===index}">
                  {{el.value}}
                </div>
              </div>
            </div>


          </div>
        </div>
    </div>

    <div class="git-item2">
      <div class="git-item-content">
        <div class="content-text">
          <div>GitIoT鸿易连--智能产品连接管理套</div>
          <p>基于开源鸿蒙软总线的统一协议，实现跨平台设备的互联接入和智能产品全生命周期管理服务，
            解决用户在设备物联、数据采集分析、OTA升级、运维管理和运营服务过程中设备类型多、部署地分散、
            运维成本大、数据处理难、数据安全隐患、问题溯源难等痛点，满足设备远程可视化管理、设备故障远程定位诊断、设备预警维护等需求。</p>


          <div class="item-list">
            <div class="item-box" v-for="(el,index) in items2" :key="index" @mouseenter="activeChange('itemActive2',index)">
              <div>
                <span>{{el.title}}</span>
                <a-icon v-if="active.itemActive2===index" type="down" style="color: #9A9A9A" />
                <a-icon v-else type="up" style="color: #9A9A9A" />

              </div>
              <div :class="{'item-con-text':active.itemActive2===index}">
                {{el.value}}
              </div>
            </div>
          </div>

        </div>
        <img src="@/assets/img/git2.png" alt="">
      </div>
    </div>

    <div class="git-item3">
      <div class="git-item-content">
        <img src="@/assets/img/git3.png" alt="">
        <div class="content-text">
          <div>GitIoT鸿易数--数据采集分析可视化套件</div>
          <p>融合了物联网、区块链、AI和BI工具，提供从数据采集、数据加工处理、数据存储、数据分析、数据可视化于一体的完整解决方案，解决海量数据类型不同，
            沦为数据垃圾需要盘活为数据资产等问题，创造性地将各种“重科技”轻量化，
            提供上百种数据图形模版，只需简单“拖拉拽”编辑即可实现数据展示组合，帮助管理人员及运营人员更加直观简便地获取运营数据，指导运营和生产。</p>

          <div class="item-list">
            <div class="item-box" v-for="(el,index) in items3" :key="index" @mouseenter="activeChange('itemActive3',index)">
              <div>
                <span>{{el.title}}</span>
                <a-icon v-if="active.itemActive3===index" type="down" style="color: #9A9A9A" />
                <a-icon v-else type="up" style="color: #9A9A9A" />

              </div>
              <div :class="{'item-con-text':active.itemActive3===index}">
                {{el.value}}
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="git-item4">
      <div class="git-item-content">
        <div class="content-text">
          <div>GitIoT鸿易码--低代码应用开发工具套件</div>
          <p>鸿易码套件是针对物联网场景提供的生产力工具，可覆盖各个物联网行业核心应用场景，
            高效经济地完成智能产品、服务及应用开发，加速典型场景应用的物联网数字化转型。
            鸿易码套件提供了可视化应用开发、服务开发等一系列便捷的物联网开发工具，
            解决物联网开发领域开发链路长、定制化程度高、投入产出比低、技术栈复杂、协同成本高、方案移植困难的问题。</p>

          <div class="item-list">
            <div class="item-box" v-for="(el,index) in items4" :key="index" @mouseenter="activeChange('itemActive4',index)">
              <div>
                <span>{{el.title}}</span>
                <a-icon v-if="active.itemActive4===index" type="down" style="color: #9A9A9A" />
                <a-icon v-else type="up" style="color: #9A9A9A" />

              </div>
              <div :class="{'item-con-text':active.itemActive4===index}">
                {{el.value}}
              </div>
            </div>
          </div>

        </div>
        <img src="@/assets/img/git4.png" alt="">
      </div>
    </div>

    <div class="git-item5">
      <div class="git-item-content">
        <img src="@/assets/img/git5.png" alt="">
        <div class="content-text">
          <div>GitIoT鸿易用--云端定制开发套件</div>
          <p>为需要独立部署的IoT物联网企业提供完整的产品解决方案私有云部署服务，
            包括设备开发、设备连接、大数据、设备管理、设备运营等企业全面IoT需求，
            有效缩短企业建设周期、大大降低研发成本、提供企业所在价值链的生产效率。</p>

          <div class="item-list">
            <div class="item-box" v-for="(el,index) in items5" :key="index" @mouseenter="activeChange('itemActive5',index)">
              <div>
                <span>{{el.title}}</span>
                <a-icon v-if="active.itemActive5===index" type="down" style="color: #9A9A9A" />
                <a-icon v-else type="up" style="color: #9A9A9A" />

              </div>
              <div :class="{'item-con-text':active.itemActive5===index}">
                {{el.value}}
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="scene">
      <div class="scene-title">应用场景</div>

      <a-carousel class="scene-carousel" arrows autoplay>
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: -58px;zIndex: 1"
          @mouseenter="imgLeft=false"
          @mouseleave="imgLeft=true"
        >
          <img v-show="imgLeft" src="@/assets/img/gitScene/scene-left.png" alt="">
          <img v-show="!imgLeft" src="@/assets/img/gitScene/scene-left-red.png" alt="">
        </div>
        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow"
             @mouseenter="imgRight=false"
             @mouseleave="imgRight=true" style="right: -58px">
          <img v-show="imgRight" src="@/assets/img/gitScene/scene-right.png" alt="">
          <img v-show="!imgRight" src="@/assets/img/gitScene/scene-right-red.png" alt="">
        </div>
        <div class="scene-carousel-item" v-for="(el,index) in sceneList" :key="index">
            <div>
                <p>{{el.text}}</p>
                <div>
                  <div></div>
                  <div></div>
                  <div>{{el.title}}</div>
                </div>
            </div>
            <img :src="el.img" alt="">
        </div>
      </a-carousel>

    </div>

    <div class="git-case">
        <div class="git-case-title">客户案例</div>
        <div class="git-case-list">
          <img v-for="(el,index) in caseList" :key="index" :src="el.img" alt="">
        </div>

    </div>


  </div>
</template>

<script>
import VPopVideo from "../../components/VPopVideo";
export default {
  name: "index",
  components: { VPopVideo },
  data() {
    return {
      consultShow:false,
      visible:false,
      videoURL:'https://hlcloud-prod.obs.cn-north-4.myhuaweicloud.com/mainbanner.mp4',
      active:{
        itemActive1:0,
        itemActive2:0,
        itemActive3:0,
        itemActive4:0,
        itemActive5:0,
      },
      imgLeft:true,
      imgRight:true,
      items1: [
        {
          title:'容器云',
          value:'基于物联网场景下的云原生应用部署，实现资源按设备数量的动态分配，节省运维成本；同时实现数据100%隔离'
        },
        {
          title:'微服务',
          value:'基于微服务化业务应用的治理与监控，提供基于系统模块的灵活配置交付，节省部署时间，减少定制化工作量'
        },
        {
          title:'开发运维',
          value:'整合 DevOps 工具链，基于自动化工具来构建、测试和交付软件，实现开发运维全过程的可视化管理'
        },
        {
          title:'自动化监控',
          value:'统一管控物联网应用服务的资源消耗，实现各类资源的监控、分析和告警'
        },
        {
          title:'弹性伸缩',
          value:'根据业务需求和策略，智能化调整弹性计算资源的管理服务，实现计算资源的自动化分配管理'
        },
        {
          title:'安全防护',
          value:'结合先进的零信任安全架构和边界网关安全，加强对数据的网络安全防护'
        },
      ],
      items2: [
        {
          title:'多样化接入方式支持',
          value:'接入方式多种选择， 接入协议多（MQTT、CoAP、HTTPS、 Modbus、私有协议等）， 满足各类设备和接入场景要求'
        },
        {
          title:'高并发快速接入安全可靠',
          value:'主流模组集成，稳定连接，高并发通信，百万级设备接入达到50毫秒响应。一机一密、双向证书认证等多种认证方式， 多种传输加密协议保障通道安全'
        },
        {
          title:'高效定义设备模型和规则',
          value:'物模型设备事件属性数据实时上报，自定义规则配置海量设备联动'
        },
        {
          title:'OTA远程升级',
          value:'基于OTA管理模块，实现远程无线升级，极大降低运营成本'
        },
      ],
      items4:[
        {
          title:'与智能产品接入无缝集成',
          value:'智能产品相关的属性、服务、事件等数据均可从鸿易联产品接入和管理模块中直接获取，低代码应用开发无缝打通，大大降低物联网开发工作量。'
        },
        {
          title:'基于逻辑层的低代码开发',
          value:'服务开发是一个物联网业务逻辑的开发工具，通过编排服务节点的方式快速完成物联网底层业务逻辑的设计。' +
            '适用于以下场景：智能产品联动、智能产品数据处理、智能产品与服务联动、生成API 、生成App的后端服务等。'
        },
        {
          title:'低门槛可视化应用搭建',
          value:'采用低代码开发方式，无需写代码，可视化编辑器拖拽组件到画布上，再配置组件的显示样式、数据源及交互动作，' +
            '满足Web、App、小程序等多终端应用开发。适用于开发状态监控、报警管理、数据查询、报表管理、协同管理、资产设施、运营维护等。'
        },
        {
          title:'丰富的模版组件库',
          value:'鸿易码拥有数量丰富的智联网应用场景解决方案模版，AIoT应用组件满足常用基础组件、视频/图形元素、三维组件、GIS组件、视频组件等多种页面装饰组件，快速提升开发效率。'
        },
      ],
      items3:[
        {
          title:'海量的设备事件分析模板',
          value:'一键制作物联网所需AIoT智能产品使用分析，AIoT智能产品活跃时间段分析，AIoT智能产品告警分析等模板，无需重复开发，即插即用'
        },
        {
          title:'丰富的AIoT数据可视化分析组件',
          value:'大量图表、交互、地图类可视化组件，支持专业的GIS可视化效果和自定义图表效果'
        },
        {
          title:'专属BI报表和大屏',
          value:'针对AIoT业务的时序分析自动化报表工具，实现业务的快速相应'
        },
        {
          title:'基于区块链的数据存储',
          value:'所有计算节点同时参与数据变动记录，实现生产数据的不可篡改性；融合MPC安全多方计算的加密技术，让数据资产更安全'
        },
      ],
      items5:[
        {
          title:"智能产品应用低成本快捷定制化服务",
          value:"面向大、小家电和运动健康行业，覆盖上百个典型场景；通过大数据推荐算法支持典型大场景自定义应用低成本、快捷定制化服务"
        },
        {
          title:"管侧云侧",
          value:"已覆盖低代码全组件库，提供行业组件库定制服务；已拥有上千种物模型，提供行业物模型库定制服务；智能系统运维工具集，提供行业系统运维定制服务；智能数据治理工具集，提供行业数据治理定制服务"
        },
        {
          title:"边侧端侧",
          value:"已覆盖AIoT全协议栈，提供定制AIoT协议开发服务；基于KaihongOS，提供国产化系统裁剪服务；已具有上百款驱动，提供国产化硬件驱动移植服务；已支持几十款芯片，提供国产化芯片适配服务"
        },
      ],
      sceneList:[
        {
          img: require('@/assets/img/gitScene/scene1.png'),
          title:'智慧燃气',
          text:'采用自主可控AIoT技术，解决管道阀井等场景燃气安全监控难题，' +
            '形成全面物联感知、大数据分析研判、多部门联动指挥的管理体系，将隐患消除于未燃。'
        },
        {
          img: require('@/assets/img/gitScene/scene2.png'),
          title:'智慧水务',
          text:'利用KaihongOS数字基座，对区域水务场景进行数字孪生全局可视化管理，创新实现自主可控、标准管控、智能运维、安全生产、超前预警和精细运营等六大业务创新。'
        },
        {
          img: require('@/assets/img/gitScene/scene3.png'),
          title:'智慧校园',
          text:'基于开鸿“万物智联”和物联网覆盖技术，实现校园各种设备自动联网、集中管控、无缝对接，统筹建设一体化智能化教学、管理与服务平台，建设平安校园、提高教学质量和效率。'
        },
        {
          img: require('@/assets/img/gitScene/scene4.png'),
          title:'智慧环保',
          text:'基于感知层鸿蒙化设备的数据采集，建设水污染治理数字化系统，彻底解决污染源的网格化布点检测，实现精准化降低并防治水污染。'
        },
        {
          img: require('@/assets/img/gitScene/scene5.png'),
          title:'智慧电力',
          text:'在配网自动化系统中，将通信单元更换为开鸿控制器实现端侧设备的鸿蒙化，实现统一操作系统、数据标准、通讯协议，快速汇聚海量设备数据辅助电力安全管理。'
        },
        {
          img: require('@/assets/img/gitScene/scene6.png'),
          title:'智慧养老',
          text:'基于鸿蒙智能化的养老院软件和硬件，为老人提供快速一键呼救的功能。通过智能智能管理系统统筹、记录和判断等等，辅助养老院管理人员快速辅助看护老人，防止紧急事故。'
        },
      ],
      caseList:[
        {
          img:require('@/assets/img/gitCase/1.png')
        },
        {
          img:require('@/assets/img/gitCase/2.png')
        },
        {
          img:require('@/assets/img/gitCase/3.png')
        },
        {
          img:require('@/assets/img/gitCase/4.png')
        },
        {
          img:require('@/assets/img/gitCase/5.png')
        },
        {
          img:require('@/assets/img/gitCase/6.png')
        },
        {
          img:require('@/assets/img/gitCase/7.png')
        },
        {
          img:require('@/assets/img/gitCase/8.png')
        },
        {
          img:require('@/assets/img/gitCase/9.png')
        },
        {
          img:require('@/assets/img/gitCase/10.png')
        },
        {
          img:require('@/assets/img/gitCase/11.png')
        },
        {
          img:require('@/assets/img/gitCase/12.png')
        },
        {
          img:require('@/assets/img/gitCase/13.png')
        },
        {
          img:require('@/assets/img/gitCase/14.png')
        },
        {
          img:require('@/assets/img/gitCase/15.png')
        },
        {
          img:require('@/assets/img/gitCase/16.png')
        },
        {
          img:require('@/assets/img/gitCase/17.png')
        },
        {
          img:require('@/assets/img/gitCase/18.png')
        },
      ]
    };
  },
  methods:{
    activeChange(name,value){
      this.active[name]=value
    }
  },
};
</script>

<style scoped lang="scss">
  .git-iot{
    background: #FFFFFF;
    &::v-deep{
      .ant-collapse-header{
        padding-left: 0;
      }
      .ant-collapse-content-box{
        padding-left: 0;
      }
    }
  }
  .git-iot-banner{
    height: 480px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    >.img{
      height: 480px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
    }
    >div{
      position: absolute;
      width: 1264px;
      left: 50%;
      top: 110px;
      transform: translate(-50%);
      z-index: 10;
      > :first-child {
        font-size: 40px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 56px;
        margin-bottom: 16px;
        z-index: 10;
        .play-icon{
          color: #ffffff;
          cursor: pointer;
        }
      }
      > :nth-child(2){
        width: 632px;
        font-size: 24px;
        color: rgba(255,255,255,0.85);
        line-height: 33px;
        margin-bottom: 48px;
        z-index: 10;
      }
      >.git-btn{
        width: 136px;
        height: 40px;
      }
    }
  }

  .git-service{
    width: 100%;
    margin: 0 auto;
    padding-top: 63px;
    padding-bottom: 64px;
    >div{
      font-size: 32px;
      font-weight: 600;
      color: rgba(0,0,0,0.85);
      line-height: 45px;
      margin-bottom: 16px;
      text-align: center;
    }
    >p{
      width: 1200px;
      font-size: 18px;
      color: rgba(0,0,0,0.65);
      line-height: 28px;
      margin: 0 auto 48px;
      text-align: center;
    }
    >img{
      display: block;
      margin: 0 auto;
      width: 1264px;
      height: 563px;
    }

  }

  .git-item-content{
    width: 1264px;
    margin: 0 auto;
    display: flex;
    >.content-text{
      width: 480px;
      >:first-child{
        font-size: 24px;
        font-weight: bold;
        color: rgba(0,0,0,0.85);
        line-height: 33px;
        margin-bottom: 12px;
      }
      >p{
        font-size: 14px;
        color: rgba(0,0,0,0.85);
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
    &::v-deep{
      .ant-collapse-header{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
      }
      .ant-collapse-item{
        border-bottom: 1px solid #E5E5E5;
      }
    }
    .content-item-text{
      font-size: 14px;
      color: rgba(0,0,0,0.65);
      line-height: 20px;
    }
  }

  .item-list{
    .item-box{
      border-bottom: 1px solid #E5E5E5;
      padding-bottom: 16px;
      >:first-child{
          padding-top: 16px;
          display: flex;
          justify-content: space-between;
        >span{
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.85);
          line-height: 22px;
        }
      }
      >:last-child{
        padding-top: 12px;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        line-height: 20px;
        display: none;
      }
      >.item-con-text{
        display: block;
        transition: all .3s;
      }
    }
  }

  .git-item1,.git-item3,.git-item5{
    width: 100%;
    background: #FAFAFA;
  }

  .git-item2,.git-item4{
    width: 100%;
    background: #FFFFFF;
    .git-item-content{
      justify-content: flex-end;
    }
    &::v-deep{
      .ant-collapse-borderless{
        background-color: #ffffff;
      }
    }
  }

  .git-item1{
    .content-text{
      padding-top: 49px;
    }
  }

  .git-item2{
    .content-text{
      padding-top: 108px;
    }
  }

  .git-item3{
    .content-text{
      padding-top: 84px;
    }
  }

  .git-item4{
    .content-text{
      padding-top: 84px;
    }
  }

  .git-item5{
    .content-text{
      padding-top: 121px;
    }
  }

  .scene{
    padding: 64px 0 115px 0;
    >.scene-title{
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: rgba(0,0,0,0.85);
      line-height: 45px;
      margin-bottom: 64px;
    }
    >.scene-carousel{
      width: 1264px;
      margin: 0 auto;
      &::v-deep{
        .slick-dots{
          bottom: -48px;
          >li{
            >button{
              width: 45px;
              background: rgba(0, 0, 0, 0.15);
            }
          }
          >li.slick-active{
            >button{width: 45px;

              background: #EA0B06;
            }

          }
        }
      }
      .scene-carousel-item{
        display: flex!important;
        >div{
          padding: 80px 32px 40px;
          flex: 1;
          background: url("../../assets/img/gitScene/scene-bg.png") no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          >p{
            font-size: 14px;
            color: #FFFFFF;
            line-height: 20px;
          }
          >div{
            display: flex;
            align-items: center;
            >:not(:last-child){
              width: 16px;
              height: 2px;
              background: #FFFFFF;
              margin-right: 4px;
            }
            >:last-child{
              font-size: 18px;
              color: #FFFFFF;
              line-height: 25px;
            }
          }
        }
        >img{
          flex: 1;
        }
      }
      .custom-slick-arrow{
        width: 48px;
        height: 50px;
        border-radius: 50%;
        background: #F8F8F9;
        border-radius: 24px;
        color: #999999;
        display: flex!important;
        justify-content: center;
        align-items: center;
        >img{
          width: 8px;
          height: 13px;
        }
      }
    }
  }

  .git-case{
    width: 100%;
    height: 601px;
    padding: 64px 0 92px 0;
    background: url("../../assets/img/gitCase/case-bg.png") no-repeat;
    background-size: cover;
    >.git-case-title{
      font-size: 32px;
      font-weight: 600;
      color: rgba(0,0,0,0.85);
      line-height: 45px;
      margin-bottom: 64px;
      text-align: center;
    }
    >.git-case-list{
      width: 1264px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      >:not(:nth-child(6n)){
       margin-right: 12px;
      }
      >img{
        width: 200px;
        height: 100px;
        margin-bottom: 12px;
      }
    }
  }

</style>
