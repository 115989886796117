
<template>
  <div class="box" v-if="visible">
    <span class="exit" @click="exit"><a-icon type="close" /></span>
    <div class="fadom"></div>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    videoURL: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      player: null, // 接收定义的vedio,在销毁时使用
      statu: false
    }
  },
  updated () {
    this.statu = !this.statu  // 取反，标识区分进入和退出时的updated
    if (this.statu) {
      this.video()
    }
  },
  methods: {
    exit () {
      this.player.dispose()  // vedio标签销毁，截止视频流的传输
      this.$emit('update:visible', false)
    },
    video () {
      let videoTag = null
      const fadom = document.getElementsByClassName('fadom') // 获取vedio标签存放的父节点，准备在该标签下添加生成vedio标签
      console.log(fadom)
      console.log(fadom.children)
      if (!fadom.children) {
        videoTag = document.createElement('video') //新建一个vedio标签
        videoTag.setAttribute('class', 'video-js vjs-default-skin box')
        // videoTag.setAttribute('controls', true)
        // videoTag.setAttribute('preload', 'auto')
        videoTag.setAttribute('src', this.videoURL)
        videoTag.setAttribute('width', '800px')
        videoTag.setAttribute('height', '600px')
        // 以上都是使用setAttribute方法给新生成的vedio标签添加属性，更多配置属性可以在vediojs官网上查看并添加
        fadom[0].appendChild(videoTag)  // 将新生成的vedio标签添加在预设好的父节点下
      }
      const options = {
        autoplay: true,
        controls: true,
        bigPlayButton: true,
        preload: 'auto',
        sources: [
          {
            src: this.videoURL, // 配置视频地址
            type: 'video/mp4'  // 配置解析的视频文件类型
          }
        ]
      }
      this.player = videojs(videoTag, options, function () {
        this.play()  // 使用vediojs方法，实现视频播放的功能，videoTag是上面生成的新vedio标签，optins是上面配置的sources属性
      })
    }
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style lang='scss' scoped>
.box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 600px;
  width: 800px;
  z-index: 9999;
  .video {
    width: 800px;
    height: 600px;
  }
  .exit {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    right: 0;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    z-index: 10000;
    color: rgb(255, 255, 255);
    background-color: black;
    opacity: 0.5;
    cursor: pointer;
  }
}

</style>



